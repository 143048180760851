import React, {useState, useEffect} from 'react';
import $ from 'jquery';
import 'components-jqueryui';
import {BarChart, ColumnChart, LineChart} from 'react-chartkick';
import 'chart.js';
import {AlignLeft, BarChart2, GitPullRequest, MoreVertical} from "react-feather";
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getAdReport} from "../../../redux/actions/adReportActions";
import cogoToast from "cogo-toast";

const FlotChart = (props) => {
    const dispatch = useDispatch();
    const chart_type = useSelector((state) => state.ad_report.chart_type);

    useEffect(() => {
        $('#start_from').datepicker({
            showOtherMonths: true,
            selectOtherMonths: true,
            defaultDate: '+1w',
            numberOfMonths: 1,
            minDate: props.ad_data.start_date.slice(0,10),
            maxDate: new Date(props.current_date),
            dateFormat: 'yy/mm/dd',
        }).datepicker('setDate', props.ad_data.start_date.slice(0,10));

        $('#end_from').datepicker({
            showOtherMonths: true,
            selectOtherMonths: true,
            numberOfMonths: 1,
            minDate: props.ad_data.start_date.slice(0,10),
            maxDate: new Date(props.current_date),
            dateFormat: 'yy/mm/dd',
        }).datepicker('setDate', new Date(props.selected_date));
    }, []);

    const handleChart = (type) => {
        dispatch({
            type: 'CHART_TYPE',
            payload: type
        });
    };

    const option = {
        hideAfter: 5
    };

    var chart_data = props.chart_data;

    const handleFilter = () => {
        let start_date = $("#start_from").val()+" 00:00:00";
        let end_date = $("#end_from").val()+" 23:59:59";

        if(start_date > props.ad_data.end_date) {
            return cogoToast.error(`Sorry, select the date between ${props.ad_data.start_date.slice(0,10)} to ${props.ad_data.end_date.slice(0,10)}.`, option);
        }

        if(start_date > end_date) {
            return cogoToast.error("Enter date range correctly in order.", option);
        }

        props.ad_data.start_date = start_date;
        props.ad_data.end_date = end_date;
        dispatch(getAdReport(props.ad_data, start_date, end_date));
    };

    let renderChart = () => {
        if(chart_type === "barchart") {
            return (
                <BarChart
                    data={chart_data}
                    download={true} legend="bottom"
                    height="400px"
                    colors={["#3843da", "#da3849", "#da6a38"]}
                    messages={{empty: "No data Available"}}
                    dataset={{borderWidth: 1}}
                />
            );
        }

        if(chart_type === "columnchart") {
            return (
                <ColumnChart
                    data={chart_data}
                    download={true} legend="bottom"
                    height="400px"
                    colors={["#3843da", "#da3849", "#da6a38"]}
                    messages={{empty: "No data Available"}}
                    dataset={{borderWidth: 1}}
                />
            );
        }

        return (
            <LineChart
                data={chart_data}
                download={true} legend="bottom"
                height="400px"
                curve={false}
                colors={["#3843da", "#da3849", "#da6a38"]}
                messages={{empty: "No data Available"}}
                dataset={{borderWidth: 1}}
            />
        );
    };

    return(
        <div className="card card-crypto mg-t-15">
            <div className="card-header pd-y-8 d-sm-flex align-items-center justify-content-between">
                <div className="dropdown-file">
                    <a href="" className="dropdown-link" data-toggle="dropdown"><MoreVertical/></a>
                    <div className="dropdown-menu dropdown-menu-right">
                        <Link to="#" onClick={handleChart.bind(null, "linechart")} className="dropdown-item details"><GitPullRequest/> Line Chart</Link>
                        <Link to="#" onClick={handleChart.bind(null, "barchart")} className="dropdown-item details"><AlignLeft/> Bar Chart</Link>
                        <Link to="#" onClick={handleChart.bind(null, "columnchart")} className="dropdown-item details"><BarChart2/> Column Chart</Link>
                    </div>
                </div>
                <div className="tx-12 tx-color-03 align-items-center d-sm-flex">
                    <input type="text" id="start_from" className="tx-spacing-1 tx-rubik" placeholder="Start Date" style={{border: 0, width: 80}}/>
                    <span className="mg-r-10 mg-l-10">to</span>
                    <input type="text" id="end_from" className="tx-spacing-1 tx-rubik" placeholder="End Date"  style={{border: 0, width: 80}}/>
                    <nav className="nav nav-icon-only mg-l-10">
                        <Link to="#" onClick={handleFilter.bind()} className="nav-link"><i className="fa fa-search"/> Filter</Link>
                    </nav>
                </div>
            </div>
            <div className="card-body pd-10 pd-sm-20">
                {renderChart()}
            </div>
        </div>
    )
};

export default FlotChart;
