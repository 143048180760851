import React, {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import Profile from "../../assets/img/profile.png";
import {Menu, Bell, PlusCircle, Home, Users, User, FilePlus, FileText, File, LogOut, Settings, X, Briefcase} from 'react-feather';
import $ from 'jquery';
import PerfectScrollbar from 'perfect-scrollbar';
import feather from 'feather-icons';
import {logoutUser, clearCurrentCampaign, getProfile, getCountry} from '../../redux/actions';
import Logo from "../../assets/img/invoice-logo.png";
import {Link} from "react-router-dom";
import _Logo from "../../assets/img/white_looads.png";




const Header = () => {
	const dispatch = useDispatch();
	const auth = useSelector((state) => state.auth);

	useEffect(() => {
		dispatch(getProfile());
		dispatch(getCountry());
	}, []);

	$(function(){
		'use strict';
		feather.replace();

		const psNavbar = new PerfectScrollbar('#navbarMenu', {
			suppressScrollX: true
		});



		////////// NAVBAR //////////

		// Initialize PerfectScrollbar of navbar menu for mobile only
		if(window.matchMedia('(max-width: 991px)').matches) {
			const psNavbar = new PerfectScrollbar('#navbarMenu', {
				suppressScrollX: true
			});
		}

		// Showing sub-menu of active menu on navbar when mobile
		function showNavbarActiveSub() {
			if(window.matchMedia('(max-width: 991px)').matches) {
				$('#navbarMenu .active').addClass('show');
			} else {
				$('#navbarMenu .active').removeClass('show');
			}
		}

		showNavbarActiveSub()
		$(window).resize(function(){
			showNavbarActiveSub()
		});

		// Initialize backdrop for overlay purpose
		$('body').append('<div class="backdrop"></div>');


		// Showing sub menu of navbar menu while hiding other siblings
		$('.navbar-menu .with-sub .nav-link').on('click', function(e){
			e.preventDefault();
			$(this).parent().toggleClass('show');
			$(this).parent().siblings().removeClass('show');

			if(window.matchMedia('(max-width: 991px)').matches) {
				psNavbar.update();
			}
		});

		// Closing dropdown menu of navbar menu
		$(document).on('click touchstart', function(e){
			e.stopPropagation();

			// closing nav sub menu of header when clicking outside of it
			if(window.matchMedia('(min-width: 992px)').matches) {
				var navTarg = $(e.target).closest('.navbar-menu .nav-item').length;
				if(!navTarg) {
					$('.navbar-header .show').removeClass('show');
				}
			}
		});

		$('#mainMenuClose').on('click', function(e){
			e.preventDefault();
			$('body').removeClass('navbar-nav-show');
		});

		$('#sidebarMenuOpen').on('click', function(e){
			e.preventDefault();
			$('body').addClass('sidebar-show');
		});

		// Navbar Search
		$('#navbarSearch').on('click', function(e){
			e.preventDefault();
			$('.navbar-search').addClass('visible');
			$('.backdrop').addClass('show');
		});

		$('#navbarSearchClose').on('click', function(e){
			e.preventDefault();
			$('.navbar-search').removeClass('visible');
			$('.backdrop').removeClass('show');
		});



		////////// SIDEBAR //////////

		// Initialize PerfectScrollbar for sidebar menu
		if($('#sidebarMenu').length) {
			const psSidebar = new PerfectScrollbar('#sidebarMenu', {
				suppressScrollX: true
			});


			// Showing sub menu in sidebar
			$('.sidebar-nav .with-sub').on('click', function(e){
				e.preventDefault();
				$(this).parent().toggleClass('show');

				psSidebar.update();
			})
		}


		$('#mainMenuOpen').on('click touchstart', function(e){
			e.preventDefault();
			$('body').addClass('navbar-nav-show');
		});

		$('#sidebarMenuClose').on('click', function(e){
			e.preventDefault();
			$('body').removeClass('sidebar-show');
		});

		// hide sidebar when clicking outside of it
		$(document).on('click touchstart', function(e){
			e.stopPropagation();

			// closing of sidebar menu when clicking outside of it
			if(!$(e.target).closest('.burger-menu').length) {
				var sb = $(e.target).closest('.sidebar').length;
				var nb = $(e.target).closest('.navbar-menu-wrapper').length;
				if(!sb && !nb) {
					if($('body').hasClass('navbar-nav-show')) {
						$('body').removeClass('navbar-nav-show');
					} else {
						$('body').removeClass('sidebar-show');
					}
				}
			}
		});

	});

	const onLogoutClick = (event) => {
		event.preventDefault();
		dispatch(clearCurrentCampaign());
		dispatch(logoutUser());
	};

	const handleMenu = () => {
		if(window.matchMedia('(max-width: 991px)').matches) {
			$('body').removeClass('navbar-nav-show');
		}
	};

	const createNav = () => {
		if(auth.user.address !== "" && auth.user.contact_number !== "") {
			return(
				<li className="nav-item top-menu"><Link className="nav-link" to="/create-ads" onClick={handleMenu.bind()}><FilePlus/><i class="fas fa-plus"></i> Create</Link></li>
			)
		}

		return null;
	};

	const BottomMenu = () => {
		return (
			<div className="horizontal-menu">
			<Link to="/dashboard" className="menu-item">
			  <Home size={24} />
			  <span>Home</span>
			</Link>
			<Link to="/statistics" className="menu-item">
			  <Users size={24} />
			  <span>Statistics</span>
			</Link>
			<Link to="/create-ads" className="menu-item center-butto">
			  <PlusCircle size={32} />
			  <span>Create</span>
			</Link>
			<Link to="/wallet" className="menu-item">
			  <Briefcase size={24} />
			  <span>Wallet</span>
			</Link>
			<Link to="/invoice" className="menu-item">
			  <File size={24} />
			  <span>Invoices</span>
			</Link>
			
		  </div>
		);
	};

	return (
		<header className="navbar navbar-header navbar-header-fixed">
			<Link to="" id="mainMenuOpen" className="burger-menu"><Menu/></Link>
			<div className="navbar-brand">
				<Link to="#" className="df-logo">
				  <img src={_Logo} className="ht-50 mg-t-8" alt="logo"/>
				</Link>
			</div>
			<div id="navbarMenu" className="navbar-menu-wrapper">
				<div className="navbar-menu-header">
					<Link to="#" className="df-logo">dash<span>board</span></Link>
					<Link id="mainMenuClose" to="#"><X/></Link>
				</div>
				<ul className="nav navbar-menu justify-content-center">
					<li className="nav-label pd-l-20 pd-lg-l-25 d-lg-none">Main Navigation</li>
					<li className="nav-item top-menu"><Link className="nav-link" to="/dashboard" onClick={handleMenu.bind()}> <i class="fas fa-layer-group"></i> <FileText/>   Campaign</Link></li>
					{createNav()}
					<li className="nav-item top-menu"><Link className="nav-link" to="/statistics" onClick={handleMenu.bind()}><User/><i class="fas fa-chart-line"></i>Statistics</Link></li>
					<li className="nav-item top-menu"><Link className="nav-link" to="/wallet" onClick={handleMenu.bind()}><Briefcase/><i class="fas fa-wallet"></i> Wallet</Link></li>
					<li className="nav-item top-menu"><Link className="nav-link" to="/invoice" onClick={handleMenu.bind()}><File/><i class="fas fa-receipt"></i> Invoices</Link></li>
				</ul>
			</div>
			<div className="navbar-right">
				<div className="dropdown dropdown-notification">
					<Link to="#" className="dropdown-link new-indicator" data-toggle="dropdown"><Bell/>
						{/*<span>2</span>*/}
					</Link>
					<div className="dropdown-menu dropdown-menu-right">
						<div className="dropdown-header">Notifications</div>
						<Link to="#" className="dropdown-item">
							<div className="media">
								<div className="avatar avatar-sm avatar-online"><img src={Logo} className="rounded-circle" alt=""/></div>
								<div className="media-body mg-l-15">
									<p>Welcome to <strong>New</strong> Dashboard.</p>
									<span>New message</span>
								</div>
							</div>
						</Link>
						{/*<div className="dropdown-footer"><a href="">View all Notifications</a></div>*/}
					</div>
				</div>
				<div className="dropdown dropdown-profile">
					<Link to="#" className="dropdown-link" data-toggle="dropdown" data-display="static">
						<div className="avatar avatar-sm"><img src={Profile} className="rounded-circle" alt=""/></div>
					</Link>
					<div className="dropdown-menu dropdown-menu-right tx-13">
						<h6 className="tx-semibold mg-b-5">{auth.user.email}</h6>
						<p className="mg-b-25 tx-12 tx-color-03">Administrator</p>
						<Link to="/account" className="dropdown-item"><Settings/>Account Settings</Link>
						<Link to="/" onClick={onLogoutClick.bind()} className="dropdown-item"><LogOut/>Sign Out</Link>
					</div>
				</div>
			</div>
			<BottomMenu />
		</header>
	);
};

export default Header;
