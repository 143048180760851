import React, { useEffect, useState } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { Link } from 'react-router-dom';
import Footer from '../layout/Footer';
import 'parsleyjs';
import $ from 'jquery';
import { loginUser } from '../../redux/actions/authActions';
import Logo from "../../assets/img/white_looads.png";

const Login = (props) => {
    const dispatch = useDispatch();

    //Selector
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [stateReady, setStateReady] = useState(false);

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if($('#email').parsley().isValid() === false || $('#password').parsley().isValid() === false) {
            return null;
        }

        const result = {
            email: email,
            password: password
        };

        setStateReady(true);
        dispatch(loginUser(result)).then(() => {
            setStateReady(false);
        });
    };

    useEffect(() => {
        document.title = "Sign In | LooAds";
        $(function () {
            $('#login').parsley();
        });

        return () => {
            $('#login').parsley().destroy();
        }
    }, []);

    if(isAuthenticated) {
        props.history.push('/dashboard');
    }

    let renderButton = () => {
        if(stateReady === true) {
            return(
                <button className="btn btn-brand-02 btn-block" disabled>
                    <span className="spinner-border spinner-border-sm mg-r-10" role="status" aria-hidden="true"/>Loading</button>
            )
        } else {
            return(
                <button className="btn btn-brand-02 btn-lg rounded-30 btn-block">Sign In</button>
            );
        }
    };

    return (
        <div>
            <div className="content content-fixed content-auth">
                <div className="container">
                    <div className="media align-items-stretch justify-content-center ht-100p pos-relative">
                        <div className="sign-wrapper">
                            <div className="wd-100p">
                                <div className="text-center">
                                    <img src={Logo} className="ht-90 mg-b-8" alt="logo"/>
                                </div>
                                <h2 className="tx-color-01 text-center mg-b-5 tx-32">Welcome back</h2>
                                <p className="tx-color-03 text-center tx-15 mg-b-40">Login your account with your Company Email.</p>
                                <form id="login" className="parsley-style-1" data-parsley-validate="true" onSubmit={handleSubmit.bind()}>
                                    <div id="emailWrapper" className="form-group parsley-input">
                                        {/* <label>Email address</label> */}
                                        <input id="email" type="email" className="form-control"
                                               placeholder="Email address"
                                               value={email}
                                               onChange={handleEmailChange.bind()}
                                               autoComplete="off"
                                               data-parsley-class-handler="#emailWrapper" required/>
                                    </div>
                                    <div id="passwordWrapper" className="form-group parsley-input">
                                        
                                        <input id="password" type="password" className="form-control"
                                               placeholder="Password"
                                               value={password}
                                               data-parsley-minlength="6"
                                               onChange={handlePasswordChange.bind()}
                                               data-parsley-class-handler="#passwordWrapper" required/>
                                    </div>
                                    <div className="d-flex flex-end+ mg-b-15">
                                            {/* <label className="mg-b-0-f">Password</label> */}
                                            <Link to="/forgot-password" className="tx-13">Forgot password?</Link>
                                        </div>
                                    {renderButton()}
                                    <div className="divider-text">or</div>
                                    <div className="tx-13 mg-t-20 tx-center">Don't have an account? <Link to="/register">Create Account</Link></div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer/>
        </div>
    )
};

export default Login;
