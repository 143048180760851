import React, { useEffect, useState } from 'react';
import {Link, withRouter} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import { registerUser, getCountry } from '../../redux/actions';
import Footer from "../layout/Footer";
import 'parsleyjs';
import Cleave from 'cleave.js/react.js';
import 'cleave.js/dist/addons/cleave-phone.i18n';
import Logo from "../../assets/img/white_looads.png";
import $ from "jquery";


const Register = (props) => {
    const dispatch = useDispatch();
    const auth = useSelector((state) => state.auth);
    const country = useSelector((state) => state.region.country);

    const [business_name, _business_name] = useState("");
    const [vat_number, _vat_number] = useState("");
    const [address, _address] = useState("");
    const [extra_address, _extra_address] = useState("");
    const [selected_state, _selected_state] = useState("");
    const [state, _state] = useState([]);
    const [city, _city] = useState("");
    const [postal_code, _postal_code] = useState("");
    const [person_name, _person_name] = useState("");
    const [email, _email] = useState("");
    const [contact_number, _contact_number] = useState("");
    const [password, _password] = useState("");
    const [confirm_password, _confirm_password] = useState("");
    const [code, _code] = useState("");
    const [selected_country, _selected_country] = useState("");
    const [stateReady, setStateReady] = useState(false);
    const [country_code, _country_code] = useState("ZA");
    const [country_nsn, _country_nsn] = useState("9");

    const account_info = "Account manager is the person responsible for creating and managing an LooAds advertiser account. Please insert first and last name in this field";

    const agent_info = "The Agent Code is a unique key for individuals or companies handling client accounts on LooAds. Obtain it before signing up to enjoy discounted rates negotiated with LooAds. The code, provided by authorized personnel after agreeing on contract terms, is essential for access. If you're looking to sign up as an agent without the code, contact accounts@looAds.com.";

    useEffect(() => {
        document.title = "Create Account";
        if(auth.isAuthenticated) {
            props.history.push('/dashboard');
        }

        $(function () {
            $('#register').parsley();
            

            $('[data-toggle="popover"]').popover({
                animation: true
            });

            $('body').on('click', function (e) {
                //did not click a popover toggle or popover
                if ($(e.target).data('toggle') !== 'popover'
                    && $(e.target).parents('.popover.in').length === 0) {
                    $('[data-toggle="popover"]').popover('hide');
                }
            });
        });

        

        dispatch(getCountry());

        return () => {
            $('#register').parsley().destroy();

        };
    }, []);

    const handleBusinessNameChange = (event) => {
        _business_name(event.target.value);
    };

    const handleVatChange = (event) => {
        _vat_number(event.target.value);
    };

    const handleEmailChange = (event) => {
        _email(event.target.value);
    };

    const handleCountrySelected = (event) => {
        _selected_country(event.target.value);
        let {nsn, shortCode, provinces} = country.find((result) => {
             return result.name === event.target.value;
        });

        _state(provinces);
        _country_nsn(nsn);
        _country_code(shortCode);
    };

    const handlePasswordChange = (event) => {
        _password(event.target.value);
    };

    const handleConfirmPassword = (event) => {
        _confirm_password(event.target.value);
    };

    const handleContactChange = (event) => {
        _contact_number(event.target.value);
    };

    const handleAddressChange = (event) => {
        _address(event.target.value);
    };

    const handleExtraAddressChange = (event) => {
        _extra_address(event.target.value);
    };

    const handleStateSelected = (event) => {
        _selected_state(event.target.value);
    };

    const handleCityChange = (event) => {
        _city(event.target.value);
    };

    const handlePostalCodeChange = (event) => {
        _postal_code(event.target.value);
    };

    const handlePersonNameChange = (event) => {
        _person_name(event.target.value);
    };

    const handleAgentCodeChange = (event) => {
        _code(event.target.value);
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        if(
            business_name.length > 2
            && selected_country.length > 1
            && selected_state.length > 1
            && email.length > 5
            && address.length > 3
            && city.length > 3
            && postal_code.length > 3
            && password.length > 5
            && confirm_password.length > 5
            && contact_number.length > 6
            && person_name.length > 2
        ) {
            const result = {
                business_name: business_name,
                email: email,
                vat_number: vat_number,
                country: selected_country,
                password: password,
                confirmPassword: confirm_password,
                code: code,
                address: address,
                extra_address: extra_address,
                state: selected_state,
                city: city,
                contact_number: contact_number,
                person_name: person_name,
                postal_code: postal_code,
                term: true
            };

            setStateReady(true);

            dispatch(registerUser(result)).then((res) => {
                if(res.error === true) {
                    setStateReady(false);
                    return 0;
                }

                props.history.push({
                    pathname: '/message',
                    state: { data: res.data.message, title: res.data.title, icon: res.data.icon }
                });
            });
        } else {
            return null;
        }
    };

    let renderButton = () => {
        if(stateReady === true) {
            return(
                <button disabled className="btn btn-brand-02 btn-block">
                    <span className="spinner-border spinner-border-sm mg-r-10" role="status" aria-hidden="true"/>
                    Please Wait</button>
            )
        } else {
            return(
                <button className="btn btn-brand-02 btn-block">Create Account</button>
            );
        }
    };

    return (
        <div>
            <div className="content content-fixed content-auth">
                <div className="container">
                    <div className="media align-items-stretch justify-content-center ht-100p">
                        <div className="sign-wrapper">
                            <div className="wd-100p">
                            <div className="text-center">
                                    <img src={Logo} className="ht-90 mg-b-8" alt="logo"/>
                                </div>
                                <h4 className="tx-color-01 text-center mg-b-5 tx-32">Create New Account</h4>
                                <p className="tx-color-03 mg-b-30 tx-center">Tell us a bit about your company. We just need the basics.</p>
                                <form id="register" className="parsley-style-1" data-parsley-validate="true" noValidate onSubmit={handleSubmit.bind()}>
                                    <div className="form-group" data-parsley-validate="true">
                                        <label>Country<span className="tx-danger">*</span></label>
                                        <select className="custom-select"
                                                value={selected_country}
                                                onChange={handleCountrySelected.bind()}
                                                required>
                                            <option value="" disabled>Select Country</option>
                                            {country.map((result, index) => <option key={result.name} value={result.name}>{result.name}</option>)}
                                        </select>
                                    </div>
                                    <div id="businessWrapper" className="form-group parsley-input">
                                        <label>Business Name<span className="tx-danger">*</span></label>
                                        <input type="text" className="form-control" placeholder="Enter your Business Name"
                                               value={business_name}
                                               onChange={handleBusinessNameChange.bind()}
                                               data-parsley-class-handler="#businessWrapper"
                                               autoComplete="none"
                                               required/>
                                    </div>
                                    <div className="form-group">
                                        <label>VAT Number(Optional)</label>
                                        <input type="text" className="form-control"
                                               value={vat_number}
                                               onChange={handleVatChange.bind()}
                                               autoComplete="off"
                                               placeholder="Enter VAT Number"/>
                                    </div>
                                    <div id="streetWrapper" className="form-group parsley-input">
                                        <label>Address 1<span className="tx-danger">*</span></label>
                                        <input type="text" className="form-control" placeholder="Street name/house or building #"
                                               value={address}
                                               onChange={handleAddressChange.bind()}
                                               autoComplete="none"
                                               data-parsley-class-handler="#streetWrapper" required/>
                                    </div>
                                    <div id="streetWrapper" className="form-group parsley-input">
                                        <label>Address 2 (Optional)</label>
                                        <input type="text" className="form-control" placeholder="Suburb"
                                               value={extra_address}
                                               onChange={handleExtraAddressChange.bind()}
                                               autoComplete="none"
                                               />
                                    </div>
                                    <div id="cityWrapper" className="form-group parsley-input">
                                        <label>City<span className="tx-danger">*</span></label>
                                        <input type="text" className="form-control" placeholder="Enter City"
                                               value={city}
                                               onChange={handleCityChange.bind()}
                                               autoComplete="none"
                                               data-parsley-class-handler="#cityWrapper" required/>
                                    </div>
                                    <div className="form-group" data-parsley-validate="true">
                                        <label>Province/State<span className="tx-danger">*</span></label>
                                        <select className="custom-select"
                                                value={selected_state}
                                                onChange={handleStateSelected.bind()}
                                                required>
                                            <option value="" disabled>Select State</option>
                                            {state.map((result) => <option key={result.name} value={result.name}>{result.name}</option>)}
                                        </select>
                                    </div>
                                    <div id="postalWrapper" className="form-group parsley-input">
                                        <label>Postal Code<span className="tx-danger">*</span></label>
                                        <input type="number" className="form-control"
                                               placeholder="Enter Postal Code"
                                               value={postal_code}
                                               onChange={handlePostalCodeChange.bind()}
                                               autoComplete="none"
                                               data-parsley-class-handler="#postalWrapper" required/>
                                    </div>
                                    <div id="fnameWrapper" className="form-group parsley-input">
                                        <div className="d-flex justify-content-between mg-b-5">
                                            <label>Account Manager<span className="tx-danger">*</span></label>
                                            <Link to="#" className="tx-11 mg-t-3" data-container="body" data-toggle="popover" data-placement="right" data-content={account_info}>What's this?</Link>
                                        </div>
                                        <input type="text" className="form-control" placeholder="Enter your full name"
                                               value={person_name}
                                               onChange={handlePersonNameChange.bind()}
                                               autoComplete="none"
                                               data-parsley-class-handler="#fnameWrapper" required/>
                                    </div>
                                    <div id="emailWrapper" className="form-group parsley-input">
                                        <label>Email Address<span className="tx-danger">*</span></label>
                                        <input type="email" className="form-control"
                                               placeholder="Enter valid email address"
                                               value={email}
                                               onChange={handleEmailChange.bind()}
                                               autoComplete="none"
                                               data-parsley-class-handler="#emailWrapper" required/>
                                    </div>
                                    <div id="contactWrapper" className="form-group parsley-input">
                                        <label>Contact Number<span className="tx-danger">*</span></label>
                                        <Cleave placeholder="Enter Contact Number" className="form-control"
                                                options={{
                                                    phone: true,
                                                    phoneRegionCode: country_code
                                                }}
                                                minLength={country_nsn}
                                                data-parsley-pattern="^[\d\+\-\.\(\)\/\s]*$"
                                                value={contact_number}
                                                onChange={handleContactChange.bind()}
                                                autoComplete="none"
                                                data-parsley-class-handler="#contactWrapper" required/>
                                    </div>
                                    <div id="passwordWrapper" className="form-group parsley-input">
                                        <label>Password<span className="tx-danger">*</span></label>
                                        <input id="pass" type="password" className="form-control"
                                               data-parsley-minlength="6"
                                               placeholder="Enter Password"
                                               value={password}
                                               onChange={handlePasswordChange.bind()}
                                               data-parsley-class-handler="#passwordWrapper" required/>
                                    </div>
                                    <div id="cPasswordWrapper" className="form-group parsley-input">
                                        <label>Confirm Password<span className="tx-danger">*</span></label>
                                        <input type="password" className="form-control"
                                               placeholder="Enter Confirm Password"
                                               data-parsley-minlength="6"
                                               data-parsley-equalto='#pass'
                                               value={confirm_password}
                                               onChange={handleConfirmPassword.bind()}
                                               data-parsley-class-handler="#cPasswordWrapper" required/>
                                    </div>
                                    <div className="form-group">
                                        <div className="d-flex justify-content-between mg-b-5">
                                            <label className="mg-b-0-f">Agent Code (Optional)</label>
                                            <Link to="#" className="tx-11 mg-t-3" data-container="body" data-toggle="popover" data-placement="right" data-content={agent_info}>Learn More</Link>
                                        </div>
                                        <input type="text" className="form-control"
                                               value={code}
                                               onChange={handleAgentCodeChange.bind()}
                                               autoComplete="none"
                                               placeholder="Enter agent code"/>
                                    </div>
                                    <div className="form-group tx-12">
                                        By clicking <strong>Create Account</strong> below, you agree to our <a href="https://looads.com/terms-conditions" target="_blank">Terms and Conditions</a> and Privacy Policy.
                                    </div>
                                    {renderButton()}
                                    <div className="divider-text">or</div>
                                    <div className="tx-13 mg-t-20 mg-b-40 tx-center">Already have an account? <Link to="/">Sign In</Link></div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer/>
        </div>
    )
};

export default withRouter(Register);
