import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import payment from "../../utils/payment";
import {withRouter} from 'react-router-dom';
import {DELETE_CAMPAIGN_URL, GET_CAMPAIGN_LIST} from '../../api';
import 'datatables.net';
import 'datatables.net-responsive';
import $ from 'jquery';
import axios from 'axios';
import cogoToast from "cogo-toast";
import formatNumber from "../../utils/formatNumber";
import {getCurrentCampaign} from '../../redux/actions';

const CampaignList = () => {
    const auth = useSelector((state) => state.auth);
    const token = localStorage.getItem('jwtToken');
    const dispatch = useDispatch();

    useEffect(() => {
        $('#campaign_').DataTable({
            responsive: true,
            "language": {
                "processing": '<div class="spinner-border"></div><p class="mg-t-10 tx-12">Loading Advertiser Report</p>'
            },
            "searching": true,
            "lengthMenu": [[10], [10]],
            ordering: false,
            info: true,
            bFilter: false,
            processing: true,
            pageLength: 10,
            serverSide: true,
            ajax: function(data, callback) {
                // make a regular ajax request using data.start and data.length
                $.ajax(GET_CAMPAIGN_LIST, {
                    type: 'POST',
                    headers: {
                        "Authorization": token
                    },
                    data: {
                        query: $('.dataTables_filter input').val(),
                        pageSize: data.start,
                        format: 'json',
                        pageIndex: (data.length + data.start) / data.length
                    },
                    success : function(res) {
                        callback({
                            recordsTotal: res.total,
                            recordsFiltered: res.total,
                            data: res.data
                        });
                    }
                });
            },
            "columns": [
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"},
                {"data": "raw"}
            ],
            "columnDefs": [
                {
                    "render": function (data) {
                        return data.name;
                    },
                    "targets": 0
                },
                {
                    "render": function (data) {
                        return data.ads.length;
                    },
                    "targets": 1
                },
                {
                    "render": function (data) {
                        return `R${formatNumber(data.budget)}`;
                    },
                    "targets": 2
                },
                {
                    "render": function (data) {
                        let renderStatus = (status) => {
                            return {
                                active: `<span class="badge badge-success">Active</span>`,
                                complete: `<span class="badge badge-primary">Complete</span>`,
                                achieve: `<span class="badge badge-info">Achieved</span>`,
                                decline: `<span class="badge badge-danger">Decline</span>`,
                                pending: `<span class="badge badge-warning">Pending</span>`,
                                awaiting: `<span class="badge badge-info">Awaiting</span>`
                            }[status]
                        };

                        return renderStatus(data.status);
                    },
                    "targets": 3
                },
                {
                    "render": function (data) {
                        let renderStatus = (status) => {
                            $("#payment").unbind('click');
                            $('#payment').click(function(){
                                payment($("#payment").attr("data-name"), $("#payment").attr("data-budget"), $("#payment").attr("data-id"), auth.user.sub, auth.user.business_name);
                            });

                            $("#delete").unbind('click');
                            $('#delete').click(function(){
                                let id = $("#delete").attr("data-id");
                                axios.post(DELETE_CAMPAIGN_URL, {id}).then((response) => {
                                    if(response.data === "success") {
                                        dispatch(getCurrentCampaign());
                                    } else {
                                        cogoToast.error("Something went wrong.");
                                    }
                                });
                            });

                            $("#delete_").unbind('click');
                            $('#delete_').click(function(){
                                let id = $("#delete_").attr("data-id");
                                axios.post(DELETE_CAMPAIGN_URL, {id}).then((response) => {
                                    if(response.data === "success") {
                                        dispatch(getCurrentCampaign());
                                    } else {
                                        cogoToast.error("Something went wrong.");
                                    }
                                });
                            });

                            return {
                                active: `<nav class="nav nav-icon-only"><a href="#/campaign-ads/${data._id}/${data.name}" class="nav-link"><i class="fa fa-eye"></i> View</Link></nav>`,
                                complete: `<nav class="nav nav-icon-only"><a href="#" class="nav-link"><i class="fa fa-eye"></i> View</Link></nav>`,
                                achieve: `<nav class="nav nav-icon-only"><a href="#" class="nav-link"><i class="fa fa-eye"></i> View</Link></nav>`,
                                decline: `<nav class="nav nav-icon-only">
                                                <a href="#" class="nav-link"><i class="fa fa-eye"></i> View</a>
                                                <a href="#" onclick="return false" data-id="${data._id}" id="delete" class="nav-link"><i class="fa fa-trash-alt"></i> Delete</a>
                                            </nav>`,
                                pending: `<nav class="nav nav-icon-only">
                                                <a href="#" onclick="return false" data-id="${data._id}" data-name="${data.name}" data-budget="${data.budget}" data-id="payment" class="nav-link"><i class="fa fa-credit-card"></i> Pay</a>
                                                <a href="#" class="nav-link"><i class="fa fa-eye"></i> View</a>
                                                <a href="#" onclick="return false" data-id="${data._id}" id="delete_" class="nav-link"><i class="fa fa-trash-alt"></i> Delete</a>
                                            </nav>`,
                                awaiting: `<nav class="nav nav-icon-only"><a href="javascript:void(0)" class="nav-link"><i class="fa fa-stop-circle"></i> No Actions</a></nav>`
                            }[status]
                        };

                        return renderStatus(data.status);
                    },
                    "targets": 4
                }
            ]
        });
    }, []);

    return (
        <div>
            
        <div data-label="Campaign Lists" className="df-example demo-table">
            <table id="campaign_" className="table">
                <thead>
                <tr>
                    <th className="wd-20p">Name</th>
                    <th className="wd-15p">Ads</th>
                    <th className="wd-15p">Budget</th>
                    <th className="wd-10p">Status</th>
                    <th className="wd-20p">Actions</th>
                </tr>
                </thead>
            </table>
        </div>
        </div>
    );
};

export default withRouter(CampaignList);
