import React, { Component, useEffect } from 'react';
import {useDispatch, useSelector} from "react-redux";
import { Link } from 'react-router-dom';
import moment from "moment";
import $ from 'jquery';
import {logoutUser, clearCurrentCampaign, getProfile, getCountry} from '../../redux/actions';
import {Menu, Home, Users, Bell, User, FilePlus, FileText, File, LogOut, Settings, X, Briefcase} from 'react-feather';


const BottomMenu = () => {
	return (
        <div className="horizontal-menu">
        <Link to="/dashboard" className="menu-item">
          <Home size={24} />
          <span>Home</span>
        </Link>
        <Link to="/statistics" className="menu-item">
          <Users size={24} />
          <span>Statistics</span>
        </Link>
        <Link to="/wallet" className="menu-item">
          <Briefcase size={24} />
          <span>Wallet</span>
        </Link>
        <Link to="/invoice" className="menu-item">
          <File size={24} />
          <span>Invoices</span>
        </Link>
        <Link to="/account" className="menu-item">
          <Settings size={24} />
          <span>Settings</span>
        </Link>
      </div>
	);
};

const Footer = () => {
    return (
        <div>
        {/* Bottom Navigation Menu */}
         
        <footer className="footer">
            <div>
                <span>&copy; {moment().format("YYYY")} LooAds By <a href="https://blackstripezm.com">Blackstripe. </a></span>
                <span>All Right Reserved.</span>
            </div>
            <div>
                <nav className="nav">
                    <a href="https://looads.com/terms-conditions" target="_blank" className="nav-link">Terms And Conditions</a>
                    <a href="mailto:support@looads.com" className="nav-link">Contact Us</a>
                </nav>
            </div>
        </footer>
        </div>
    );
};

export default Footer;
