import React, { useEffect, useState } from 'react';
import {Link, withRouter} from 'react-router-dom';
import { forgotPassword } from '../../redux/actions/authActions';
import Footer from '../layout/Footer';
import feather from 'feather-icons';
import 'parsleyjs';
import {useDispatch, useSelector} from "react-redux";
import Logo from "../../assets/img/white_looads.png";
import $ from "jquery";

const ForgotPassword = (props) => {
    const dispatch = useDispatch();
    const auth = useSelector((state) => state.auth);

    const [email, _email] = useState("");
    const [stateReady, setStateReady] = useState(false);

     const handleEmailChange = (event) => {
         _email(event.target.value);
    };

    useEffect(() => {
        document.title = "Forgot Password";
        $(function () {
            $('#email').parsley();
            feather.replace();
        });

        if(auth.isAuthenticated) {
            props.history.push('/dashboard');
        }

        return () => {
            $('#email').parsley().destroy();
        }
    }, []);

    const handleSubmit = (event) => {
        event.preventDefault();

        const emailCheckRegex = /\S+@\S+\.\S+/;

        if(emailCheckRegex.test(email.replace(/\s/g, "")) && email.length > 5) {
            const result = {
                email: email
            };

            setStateReady(true);

            dispatch(forgotPassword(result)).then((res) => {
                if(res.cancel === true) {
                    setStateReady(false);
                    return 0;
                }

                props.history.push({
                    pathname: '/message',
                    state: { data: res.data.message, title: res.data.title, icon: res.data.icon }
                });
            });
        }

        return null;
    };

    let renderButton = () => {
        if(stateReady === true) {
            return(
                <button className="btn btn-brand-02 btn-block" disabled>
                    <span className="spinner-border spinner-border-sm mg-r-10" role="status" aria-hidden="true"/>Loading</button>
            )
        } else {
            return(
                <button className="btn btn-brand-02 btn-block">Reset Password</button>
            );
        }
    };

    return(
        <div>
            <div className="content content-fixed content-auth">
            <div className="container">
                <div className="media align-items-stretch justify-content-center ht-100p pos-relative">
                 <div className="sign-wrapper">
                  <div className="wd-100p">
                        <div className="text-center"><img src={Logo} className="ht-90 mg-b-8" alt="logo"/></div>
                        <h4 className="tx-color-01 text-center mg-b-5 tx-32">Reset your Password</h4>
                        <p className="tx-color-03 mg-b-30 tx-center">Enter your email address and we will send you a
                            link to reset your password.</p>
                        <form className="parsley-style-1" id="email" data-parsley-validate noValidate onSubmit={handleSubmit.bind()}>
                            <div id="passwordWrapper" className="form-group parsley-input">
                                <input type="email" className="form-control wd-sm-300 wd-xs-300 flex-fill"
                                       placeholder="Enter email address" data-parsley-class-handler="#passwordWrapper"
                                       value={email}
                                       onChange={handleEmailChange.bind()}
                                       required/>
                            </div>
                            {renderButton()}
                        </form>
                        <div className="tx-13 mg-t-20 mg-b-40 tx-center">Already have an account? <Link to="/">Sign In</Link></div>
                    </div>
                  </div>
                </div>
                </div>
            </div>

            <Footer/>
        </div>
    )
};

export default withRouter(ForgotPassword);
