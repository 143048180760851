import React, { useState, useEffect } from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { updateNewPassword } from '../../redux/actions';
import {VERIFY_PASSWORD_LINK_URL} from "../../api";
import axios from 'axios';
import Footer from "../layout/Footer";
import feather from 'feather-icons';
import 'parsleyjs';
import $ from "jquery";

const VerifyPasswordLink = (props) => {

    const auth = useSelector((state) => state.auth);
    const dispatch = useDispatch();

    const [password, _password] = useState("");
    const [status, _status] = useState("");
    const [confirm_password, _confirm_password] = useState("");
    const [loading, _loading] = useState(true);
    const [stateReady, setStateReady] = useState(false);

    const handlePasswordChange = (event) => {
        _password(event.target.value);
    };

    const handleConfirmPassword = (event) => {
        _confirm_password(event.target.value);
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        if(password.length > 5 && confirm_password.length > 5) {
            const token = props.match.params.token;

            const result = {
                password: password,
                confirmPassword: confirm_password
            };

            setStateReady(true);

            dispatch(updateNewPassword(result, token)).then((res) => {
                if(res.cancel === true) {
                    setStateReady(false);
                    return 0;
                }

                props.history.push({
                    pathname: '/message',
                    state: { data: res.data.message, title: res.data.title, icon: res.data.icon }
                });
            });
        }

        return null;
    };

    document.title = "New Password";

    $(function () {
        feather.replace();
    });

    useEffect(() => {
        if(auth.isAuthenticated) {
            props.history.push('/dashboard');
        }

        const token = props.match.params.token;
        axios.post(VERIFY_PASSWORD_LINK_URL+`/token/`+token).then(res => {
            const {data} = res.data;
            _status(data);
            _loading(false);
        }).catch(() => {
            _loading(false);
        });
    }, []);

    if(loading === true) {
        return (
            <div className="content content-fixed content-auth-alt">
                <div className="container d-flex justify-content-center ht-100p">
                    <div className="mx-wd-300 wd-sm-450 ht-100p d-flex flex-column align-items-center justify-content-center">
                        <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                        <p className="mg-t-10">Verifing</p>
                    </div>
                </div>
            </div>
        );
    } else {
        if(status === true) {
            let renderButton = () => {
                if(stateReady === true) {
                    return(
                        <button className="btn btn-brand-02 btn-block">
                            <span className="spinner-border spinner-border-sm mg-r-10" role="status" aria-hidden="true"/>Loading</button>
                    )
                } else {
                    return(
                        <button className="btn btn-brand-02 btn-block">Update Password</button>
                    );
                }
            };

            return(
                <div>
                    <div className="content content-fixed content-auth-alt">
                        <div className="container d-flex justify-content-center ht-100p">
                            <div className="mx-wd-300 wd-sm-450 ht-100p d-flex flex-column align-items-center justify-content-center">
                                <div className="wd-80p wd-sm-300 mg-b-15 tx-center"><i className="wd-60 ht-60" data-feather="key"/></div>
                                <h4 className="tx-20 tx-sm-24">New password</h4>
                                <p className="tx-color-03 mg-b-30 tx-center">Enter your new password to update.</p>
                                <form className="parsley-style-1" data-parsley-validate noValidate onSubmit={handleSubmit.bind()}>
                                    <div id="passwordWrapper" className="form-group parsley-input">
                                        <label>Password<span className="tx-danger">*</span></label>
                                        <input id="pass" type="password" className="form-control wd-sm-300 wd-xs-300"
                                               data-parsley-minlength="6"
                                               placeholder="Enter Password"
                                               value={password}
                                               onChange={handlePasswordChange.bind()}
                                               data-parsley-class-handler="#passwordWrapper" required/>
                                    </div>
                                    <div id="cPasswordWrapper" className="form-group wd-sm-300 wd-xs-300 parsley-input">
                                        <label>Confirm Password<span className="tx-danger">*</span></label>
                                        <input type="password" className="form-control"
                                               placeholder="Enter Confirm Password"
                                               data-parsley-minlength="6"
                                               data-parsley-equalto='#pass'
                                               value={confirm_password}
                                               onChange={handleConfirmPassword.bind()}
                                               data-parsley-class-handler="#cPasswordWrapper" required/>
                                    </div>
                                    {renderButton()}
                                </form>
                            </div>
                        </div>
                    </div>

                    <Footer/>
                </div>
            );
        } else {
            return(
                <div className="content content-fixed content-auth-alt">
                    <div className="container ht-100p">
                        <div className="ht-100p d-flex flex-column align-items-center justify-content-center">
                            <div className="container ht-100p">
                                <div className="ht-100p d-flex flex-column align-items-center justify-content-center">
                                    <div className="wd-80p wd-sm-300 mg-b-15 tx-center"><i className="wd-60 ht-60" data-feather="link"/></div>
                                    <h4 className="tx-20 tx-sm-24 tx-center">This link does not exist</h4>
                                    <p className="tx-color-03 mg-b-40">This link is either does not exist or link has been deleted.</p>
                                    <div className="tx-13 tx-lg-14 mg-b-40 tx-center">
                                        <a href="#" className="btn btn-brand-02 d-inline-flex align-items-center">Home Page</a>
                                        <a href="mailto:support@looads.com" className="btn btn-white d-inline-flex align-items-center mg-l-5">Contact Support</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }
};

export default VerifyPasswordLink;
